@mixin flex-display($direction: row, $justifyContent: flex-start, $alignItems:flex-start){
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin normal-text(
  $withBg: false,
  $lineHeight: normal,
  $light:false,
  $fontSize: 1rem){
  @if $light{
    font-family: "Calibri Light", sans-serif;
  }@else {
    font-family: Calibri, sans-serif;
  }
  line-height: $lineHeight;
  font-size: $fontSize;
  @if $withBg{
    color: $textColor2;
  }@else{
    color: $textColor;
  }
}

@mixin lineClamp($lines:1){
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: clip;
}

$backgroundColor: white;
$textColor:black;
$primaryColor: #d0884d;
$secondaryColor: #edcfb5;
$tagColor: #e3d2c2;
$textColor2: #e9e7e2;

$mobile-width: 768px;
$tablet-width: 960px;
$desktop-width: 1368px;
@mixin mobile {
  @media (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}
@mixin tablet-lg {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}