@import "src/shared/variables";
.container{
  @include flex-display(column,flex-start,center);
  width: 100%;
}

.topPic{
  width: 100%;
  height: 31.82vw;
  background: black;
  z-index: 1;
}

.picInfo{
  position: absolute;
  z-index: 3;
  top: 29.5vw;
  left: 2%;
  @include normal-text(true,normal,true,0.875rem);
  font-style: italic;
  color: $secondaryColor;
}

.control{
  @include flex-display(column, center,center);
  width: 70%;
  position: relative;
  z-index: 2;
  top: -7vw;

  .drawing{
    color: white;
    font-size: 3.75rem;
    position: relative;
    width: 38%;
    height: 14vw;
    background-color: $primaryColor;
    opacity: .4;
  }
  .drawing::after{
    content: 'DRAWING';
    position: absolute;
    bottom: 1vw;
    left: 50%;
    transform: translateX(-50%);
    background: transparent;
    color: white;
    opacity: 1;
  }
}
.filter{
  @include flex-display(row,space-between,center);
  list-style: none;
  @include normal-text(false);
  font-size: 1rem;
  width: 25%;
  padding:0;
  li:hover{
    cursor: pointer;
  }
}
.filterFocus{
  color: $primaryColor;
  border-bottom: 3px double $primaryColor;
}
.drawingBoard{
  width: 100%;
  @include flex-display(column,center,center);
  position: relative;

}

.subsection{
  width: 100%;
  margin-bottom: 6%;
  @include flex-display(column,flex-start,center);
  .intro{
    @include flex-display(row,flex-start,flex-start);
    width: 50%;
    padding-bottom: 4%;
    li{
      @include normal-text(false,normal,false,1.5rem);
      font-weight: bold;
      color:$primaryColor;
      list-style: none;
      min-width: 20%;
    }
    span{
      @include normal-text(false,1.5rem,true);
    }
  }

}

@include mobile{
  .topPic{
    height: 40vh;
    object-fit: cover;
  }
  .picInfo{
    display: none;
  }
  .control{
    top:0;
    width: 80%;
    .drawing{
      margin-top: -15vw;
      width: 70%;
      height: 30vw;
      font-size: 2.5rem;
    }
    .drawing::after{
      bottom: 2vw;
    }
  }
  .filter{
    @include flex-display(row,space-between,center);
    list-style: none;
    @include normal-text(false);
    font-size: 1.125rem;
    width: 80%;
    margin: 4% 0 5% 0;
    li{
      padding: 0;
    }
  }
  .subsection{
    margin: 5% 0;
    .intro{
      flex-direction: column;
      width: 70%;
      padding-bottom: 10%;
    }
  }
}