@import "src/shared/variables";
.container{
  background-image: url('https://dzh2mqdszgu4f.cloudfront.net/images/error/error.jpg');
  width: 100%;
  height:100vh;
  background-size:cover;
  @include flex-display(column,space-between,center);
  @include normal-text(true);
  padding: 8% 0;
  position: relative;

  &::after{
    content: "";
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: .2;
  }

  span:nth-child(1){
    font-size: 2rem;
    color: white;
    font-weight: bold;
    z-index: 2;
  }
  span:nth-child(2){
    font-size: 12rem;
    font-weight: bold;
  }
  .msg{
    @include flex-display(column,flex-start,center);
    margin: 0 0 4% 0;
    span{
    }
    span:nth-child(2){
      font-size: 1rem;
      font-weight: normal;
      margin-top: 1%;
    }
  }

  .button{
    z-index: 2;
    background: transparent;
    border: 2px solid white;
    opacity: .7;
    @include normal-text(true);
    width: auto;
    padding: 1%;
    border-radius: 6px;
    cursor: pointer;
  }
  .copywrite{
    position: absolute;
    bottom:2%;
  }
}
@include mobile{
  .container{
    padding: 28% 0;
  }

}
