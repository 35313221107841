@import "../shared/variables";
.header{
  width: 100%;
  background: transparent;
  background-size: cover;
  padding: 1% 2% 0 2%;
  @include flex-display(row, space-between, flex-start);
  position: absolute;
  top: 0;
  z-index: 9;
}


.logo{
  width: 2%;
  margin-top: .8%;
  user-select: none;
  -webkit-user-drag: none;
}

.menu{
  @include flex-display(row,space-between,center);
  width: 40%;
  list-style: none;
  pointer-events: all;
  user-select:none;
}

.navLink{
  text-decoration: none;
  font-family: Calibri,sans-serif;
  color: $textColor2;
  font-size: 1rem;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 0.3s ease;
  &:hover{
    border-bottom-color: $secondaryColor;
    text-shadow: white 0 -1px;
    color: white;
  }
}
.subMenuContainer{
  @include flex-display(column,flex-start,flex-start);
  .subMenu{
    position: absolute;
    top: 3.3rem;
    padding-left: 0;
    @include flex-display(column,flex-start,flex-start);
    list-style: none;
    pointer-events: all;
    user-select:none;
    color: $textColor2;
    cursor: pointer;
    display: none;
    height: auto;

    li{
      @include normal-text(true);
      width: 100%;
      padding: .2rem 0;
    }

    li:hover{
      color: $secondaryColor;
    }
  }

  &:hover .subMenu{
    display: flex;
    padding-top: 1rem;
    transform: translateY(-1rem);
  }

  &:hover .navLink{
    border-bottom-color: $secondaryColor;
    text-shadow: white 0 -1px;
    color: white;
  }
}

@include mobile{
  .logo{
    width: 7%;
  }
  .menu{
    @include flex-display(column,flex-start,flex-start);
    width: 60vw;
    height: 100%;
    padding: 5%;
    list-style: none;
    pointer-events: all;
    user-select:none;
    background: rgba(0,0,0,1);
    margin: 0;
  }
  .navLink{
    text-decoration: none;
    font-family: Calibri,sans-serif;
    font-size: 1.25rem;
    cursor: pointer;
  }
  li{
    padding: 5% 0;

  }
  .subMenuContainer{
    @include flex-display(column,flex-start,flex-start);
    width: 100%;
    .subMenu{
      @include flex-display(column,flex-start,flex-start);
      position: static;
      width: 100%;
      height: auto;
      list-style: none;
      pointer-events: all;
      user-select:none;
      cursor: pointer;
      li{
        @include normal-text(true);
        width: 100%;
        padding-left: 10%;
        font-size: 1.1rem;
      }
    }
  }
}

@include tablet{
  .logo{
    width: 20%;
  }
  .menu{
    width: 50%;
  }
}
@include tablet-lg{
  .menu{
    width: 50%;
  }
}