@import "src/shared/variables";
.container{
  @include flex-display(column,center,center);
    border: 2px solid var(--color,#000);
    position: relative;

    &::before,&::after {
      content: '';
      display: block;
      position: absolute;
      background: #fff;
    }
    &::before{
      top:calc(var(--thickness,5px)*-1);
      bottom: var(--size,10px);
      left: var(--size,10px);
      right: calc(var(--thickness,5px)*-1);
    }

    &::after {
      left: calc(var(--thickness,5px)*-1);
      right: var(--size,10px);
      top: var(--size,10px);
      bottom: calc(var(--thickness,5px)*-1);
    }
  }
  .content{
    position: relative;
    z-index: 4;
    width: 100%;
    height: 100%;
  }

