@import "src/shared/variables";
.container{
  @include flex-display(column,flex-start,center);
  width: 100%;
}
.topPic{
  width: 100%;
  height: 100vh;
  background: black;
  z-index: 1;
}
.onPic{
  position: absolute;
  top:48vh;
  left:10%;
  right:10%;
  z-index: 2;
  .title{
    @include flex-display(column);
    span:nth-child(1){
      @include normal-text($fontSize:3.75rem);
      color: $primaryColor;
    }
    span:nth-child(2){
      @include normal-text($lineHeight:1.5rem);
      color: $secondaryColor;
    }
  }
  .basicInfo{
    @include flex-display(column);
    @include normal-text($lineHeight:1.9rem);
    color: white;
    margin: 3% 0;
  }
}
.contentBoard{
  width: 70%;
}
.threeBlocks{
  padding: 0 1vw;
}
.textBlock{
  @include flex-display(row,space-between,flex-start);
  width: 100%;
  h2{
    width: 28%;
    margin: 0;
    @include flex-display(row,flex-start,center);
    @include normal-text(true,normal,true,3.75rem);
    font-weight: normal;
    line-height: 2.8rem;
    svg{
      transform: scale(1.8);
    }
  }
  div{
    width: 63.3%;
    @include normal-text(false,1.5rem,false,1rem);
    p{
      margin: 0 0 1rem 0;
      text-align: justify;
    }
  }
}
.titleLeft{
  div{
    width: 55%;
  }
  svg{margin-left: 5%;}
}
.titleRight{
  h2{justify-content: flex-end}
  svg{margin-right: 5%;}
}

.insertPics{
  margin: 10% 0;
  @include flex-display(row,flex-start,center);
  width: 100%;
  position: relative;
  padding-top: 35.71%;
  img{
    height: 100%;
    object-fit: cover;
    position: absolute;
    top:0;
    bottom: 0;
  }
  img:nth-child(1){
    left: 0;
  }
  img:nth-child(2){
    right: 0;
  }
}
.wowPics{
  position: relative;
  margin: 10% 0;
  @include flex-display(column,center,center);
  gap: 2rem;
  img{
    max-height: 82vw;
    //height: 100%;
  }
}
.galleryPics{
  margin-bottom: 10%;
  position: relative;
  padding-top: 20%;
  @include flex-display(row,space-between,center);
  img{
    width: 32.6%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top:0;
    bottom: 0;
    cursor: pointer;
  }
  img:nth-child(2){
    left:33.7%
  }
  img:nth-child(3){
    right:0;
  }
  .galleryPicNum{
    @include flex-display(row,center,center);
    @include normal-text($fontSize:2rem);
    font-weight: bold;
    position: absolute;
    background: rgba(0,0,0,.4);
    color:white;
    z-index: 5;
    width: 32.6%;
    height: 100%;
    right: 0;
    top:0;
    pointer-events: none;
  }
}
.comment{
  width: 100%;
  margin: 4% 0;
  @include normal-text($fontSize:0.875rem);
  font-style: italic;
  text-align: right;
}


@include mobile{
  .topPic{
    height: 40vh;
    object-fit: cover;
  }
  .onPic{
    top:15vh;
    .title span:nth-child(1){
      font-size: 2.5rem;
    }
    .title span:nth-child(2){
      line-height: 1rem;
    }
  }
  .contentBoard{
    width: 90%;
  }
  .textBlock{
    @include flex-display(column,flex-start,flex-start);
    h2{
      width: 100%;
      font-size: 3rem;
      margin: 0.5rem 0;
    }
    div{
      width: 100%;
    }
  }
  .wowPics{
    gap:1.2rem
  }
  //.insertPics{
  //  margin: 6% 0;
  //  @include flex-display(column,center,center);
  //  width: 100%;
  //  position: relative;
  //  padding-top: 0;
  //  img{
  //    position: static;
  //    height: auto;
  //    object-fit: contain;
  //  }
  //}
}