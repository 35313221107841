@import "src/shared/variables";
.container{
  width: 100%;
}
.topPic{
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.picInfo{
  position: absolute;
  z-index: 3;
  top: 93vh;
  left: 2%;
  @include normal-text(true,normal,true,0.875rem);
  font-style: italic;
  color: $secondaryColor;
}
.gridContainer{
  width: 100%;
  margin-top: -5px;
  @include flex-display(column,center,center);
  .gridItem{
    @include flex-display(column,center,center);
    width: 100%;
    padding-top: 33.3%;
    position: relative;
    display: inline-block;
    img{
      position: absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      width: 100%;
      object-fit: contain;
      margin:0;
      padding:0;
      filter:brightness(.7);
      &:hover{
        filter: none;
        cursor: pointer;
        ~div{
          color:white;
        }
      }
    }
    div{
      position: absolute;
      @include normal-text(true,normal,false,2rem);
      font-weight: bold;
      top:50%;
      left:50%;
      transform: translate(-50%, -50%);
      user-select: none;
    }
  }
}

@include mobile{
  .topPic{
    height: 40vh;
    object-fit: cover;
  }
  .picInfo{
    top:36vh
  }
  .gridContainer .gridItem{
    padding-top: 50%;
    div{
      font-size: 1.2rem;
    }
  }
}