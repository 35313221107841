@import "src/shared/variables";
.container{
  @include flex-display(column,flex-start,center);
  width: 100%;
}

.topPic{
  width: 100%;
  background: black;
  z-index: 1;
}

.picInfo{
  position: absolute;
  z-index: 3;
  top: 29.5vw;
  left: 2%;
  @include normal-text(true,normal,true,0.875rem);
  font-style: italic;
  color: $secondaryColor;
}

.control{
  @include flex-display(column, center,center);
  width: 70%;
  position: relative;
  z-index: 2;
  top: -7vw;

  .project{
    color: white;
    font-size: 3.75rem;
    position: relative;
    width: 38%;
    height: 14vw;
    background-color: $primaryColor;
    opacity: .4;
  }
  .project::after{
    content: 'PROJECT';
    position: absolute;
    bottom: 1vw;
    left: 50%;
    transform: translateX(-50%);
    background: transparent;
    color: white;
    opacity: 1;
  }
}

.filter{
  @include flex-display(row,space-between,center);
  list-style: none;
  @include normal-text(false);
  font-size: 1rem;
  width: 35%;
  padding:0;
  li:hover{
    cursor: pointer;
  }
}
.filterFocus{
  color: $primaryColor;
  border-bottom: 3px double $primaryColor;
}
.quote{
  margin: 2vw 0;
  text-align: center;
  font-family: Calibri,sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  color: $primaryColor;
}

.projectBoard{
  width: 85%;
  @include flex-display(column,flex-start,center);
  min-height: 60vh;
}

.companySelection{
  @include flex-display(column,flex-end,flex-end);
  user-select: none;
  .companyRow{
    @include flex-display(row,flex-start,flex-start);
  }
  .arrow{
    width: 10vw;
    padding-top: 4vw;
  }
  .arrowVertical{
    height: 10vw;
    margin: -2vw 0 -4vw 0;
    padding-right: 4vw;
    z-index: 2;
  }

}

.typeSelection{
  @include flex-display(row,space-between,center);
  width: 100%;
}

.videoProjects{
  @include flex-display(column,flex-start,flex-start);
  width: 85%;
  margin-bottom: 8%;
  .intro{
    padding: 20px;
    width: 70%;
    .name{
      @include flex-display(row,flex-start,flex-end);
      div:nth-child(1){
        @include normal-text($light:true,$fontSize:3.75rem);
        color: $primaryColor;
      }
    }
    .keywords{
      @include normal-text($fontSize:0.875rem);
      color: $primaryColor;
      margin: 0 0 1% 10px;
    }
    .subtitle{
      font-style: italic;
    }
    p{
      @include normal-text($lineHeight:1.75,$fontSize:1rem);
      text-align: justify;
      padding: 2% 0;
    }
    span{
      @include normal-text($light:true);
    }
    .button{
      font-size: .875rem;
      margin-bottom: 4px;
      user-select: none;
      cursor: pointer;
      @include flex-display(row,flex-start,center);
      color:$primaryColor;
    }
  }
}

@include mobile{
  .topPic{
    height: 40vh;
    object-fit: cover;
  }
  .picInfo{
    display: none;
  }
  .control{
    top:0;
    width: 80%;
    .project{
      margin-top: -15vw;
      width: 70%;
      height: 30vw;
      font-size: 2.5rem;
    }
    .project::after{
      bottom: 2vw;
    }
  }
  .filter{
    @include flex-display(row,space-between,center);
    list-style: none;
    @include normal-text(false);
    font-size: 1.125rem;
    width: 90%;
    margin: 4% 0 0 0;
    li{
      padding: 0;
    }
  }
  .quote{
    font-size: 1.2rem;
    width: 80%;
    margin: 18% 0;
  }

  .typeSelection{
    @include flex-display(column,space-between,center);
  }
  .videoProjects{
    width: 100%;
    margin: 1rem 0 15% 0;
    .intro{
      padding: 4rem 0 8rem 0;
      width: 100%;
      .name{
        @include flex-display(column,flex-start,flex-start);
        div:nth-child(1){
          font-size: 2.5rem;
        }
      }
      .keywords{
        margin: 3% 0 0 0;
      }
    }
  }
}