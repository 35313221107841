@import "src/shared/variables";
.container {
  width: 100%;
  height: 100vh;
  z-index: 1;

  .element {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .muteControl{
    position: absolute;
    bottom: 3%;
    right: 2%;
    color: white;
    z-index: 5;
    border: none;
    cursor: pointer;
    user-select: none;
  }
}
