@import "src/shared/variables";
.container{
  width: 100%;
  @include flex-display(column, flex-start, center);
  min-height: 100vh;
  flex-shrink: 0;
  mask-image: linear-gradient(180deg, rgba(255,255,255,1) 95%, rgba(255,255,255,0) 100%);
}
$ratio: 100vh / 2315px;
.topPic{
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center center;
}

.onPic{
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100vh;
  @include flex-display(column,flex-start,center);
  .picInfo{
    position: relative;
    width: 100%;
    top: 95%;
    padding-left: 2%;
    @include normal-text(true,normal,true);
    font-style: italic;
    color: $secondaryColor;
  }

  .description{
    position: relative;
    top:24vh;
    width: 30%;
    font-family: "Calibri Light",sans-serif;
    font-style: italic;
    font-size: 1.5rem;
    color: $secondaryColor;
    text-align: center;
  }
}
.intro{
  width: 78%;
  margin-top: 6%;
  position: relative;
  z-index: 3;
  background: transparent;
  @include flex-display(column,flex-start,center);

  .basic{
    width: 100%;
    @include flex-display(column,flex-start,center);
    img{
      width: 25%;
      margin-bottom: 2%;
    }
    span:nth-child(2){
      font-family: "Calibri Light",sans-serif;
      font-size: 3.75rem;
      color: $primaryColor;
      text-align: center;
    }
    span:nth-child(3){
      font-family: "Calibri Light",sans-serif;
      font-weight: bold;
      font-size: 1rem;
      color: $secondaryColor;
      text-align: center;
      letter-spacing: .07rem;
    }
  }
  .summary{
    @include normal-text(false,1.5rem,false,1rem);
    width: 50%;
    margin-top: 5%;
    text-align: justify;
  }
  .moreButton{
    @include flex-display(row,flex-start,center);
    @include normal-text(false,normal,false,.875rem);
    max-width: 30%;
    color: $primaryColor;
    cursor: pointer;
    user-select: none;
    margin: 1% 0 0 -1%;
  }
  .moreInfo{
    width: 50%;
  }
  .subsections{
    @include flex-display(column, flex-start,flex-start);
    font-family: Calibri,sans-serif;
    font-size: 1.5rem;
    color: $primaryColor;
    margin: 3% 0 1% 0;
  }
  .education{
    @include normal-text(false, 1.375rem, true,.875rem);
    @include flex-display(column, flex-start,flex-start);
    margin-bottom: 1%;
    span:nth-child(1){
      font-weight: bold;
      font-family: "Calibri Light",sans-serif;
    }
  }
  .exp{
    @include normal-text(false, 1.375rem, true,.875rem);
    @include flex-display(column, flex-start,flex-start);
    margin-bottom: 2%;
    span:nth-child(1){
      font-weight: bold;
      font-family: "Calibri Light",sans-serif;
    }
    a{
      color: black;
      text-decoration: none;
      &:active{
        color: $primaryColor;
      }
      &:hover{
        color: $primaryColor;
      }
    }
  }
  .award{
    @include normal-text(false, 1.375rem,true, .875rem);
    @include flex-display(column, flex-start,flex-start);
    margin-bottom: 1%;
    span:nth-child(1){
      font-weight: bold;
      font-family: "Calibri Light",sans-serif;
    }
  }
}

@mixin aboutText($lineHeight: 1.25rem, $light: false){
  line-height: $lineHeight;
  @if $light{
    font-family: "Calibri Light", sans-serif;
  }@else {
    font-family: Calibri, sans-serif;
  }
}

@include mobile{
  $ratio:  40vh / 2315px;
  .topPic{
    height: 40vh;
  }
  .picInfo{display: none};
  .onPic{
    height: 40vh;
    .description{
      top:22%;
      width: 60%;
      font-size: 1rem;
    }
  }
  .intro{
    margin-top: 5vw;

    .basic{
      padding-top: 3vw;
      img{
        width: 40%;
      }
      span:nth-child(2){
        font-size: 3rem;
      }
      span:nth-child(3){
        font-size: .9rem;
        letter-spacing: normal;
      }
    }
    .summary{
      width: 100%;
      margin: 5vw 0;
    }
    .moreButton{
      max-width: 50%;
    }
    .moreInfo{
      width: 100%;
    }
    .subsections{
      margin: 7% 0 4% 0;
    }
  }
}