@import "src/shared/variables";
.container{
  width: 100%;
  height:100vh;
  background-image: url("https://dzh2mqdszgu4f.cloudfront.net/images/contact/bg_contact.jpg");
  background-size: cover;
  @include flex-display(row,center,flex-start);
  position: relative;
  padding: 20% 0 10% 0;
  gap:10%;
  .title{
    @include normal-text($fontSize:3.75rem);
    color: white;
  }
  .info{
    width: 30%;
    .basic{
      width: 100%;
      section{
        margin: 4% 0;
        width: 100%;
        @include flex-display(row,flex-start,center);
        span{
          padding-left: 1rem;
          @include normal-text($withBg:true, $fontSize:1.5rem);
        }

      }
    }
    .social{
      width: 45%;
      margin: 13% 0;
      @include flex-display(row,space-between,center);
    }
  }

  .copywrite{
    @include normal-text(false);
    color: $textColor2;
    font-size: .8rem;
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    margin:0;
  }
  .icon{
    transform: scale(1.6);
  }
}

@include mobile{
  .container{
    @include flex-display(column,flex-start,center);
    padding-top: 45%;
    .info{
      width: 65%;
      @include flex-display(column,center,center);
      .social{
        width: 70%;
      }
      .basic section{
        margin: 10% 0;
      }
    }
  }
}