@import "src/shared/variables";
.container {
  img {
    width: 100%;
  }

  div {
    width: 100%;
    margin-top: -5%;

    @include flex-display(column, center, center);

    span {
      @include normal-text(false, normal, false, 1.5rem);
      width: 80%;
      text-align: center;
      height: 1.8rem;
    }
    p {
      @include normal-text(false, normal, true, .875rem);
      text-align: center;
      width: 70%;
      height: 3rem;
    }
  }

  &:hover {
    div span {
      font-weight: bold;
      color: $primaryColor;
    }
  }
}
@include mobile{
  .container{
    div span{
      font-size: 1rem;
    }
  }
}