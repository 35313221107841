@import "src/shared/variables";
.swiper{
  width: 100%;
  .slide{
    text-align: center;
    font-size: 18px;
    background: #fff;
    @include flex-display(column,center,center);
  }
  .img{
    display: block;
    width: 100%;
    height: 100%;

    object-fit: cover;
    padding-bottom: 2%;
  }
  .imgWithPadding{
    display: block;
    width: 100%;
    max-height: 36vw;
    padding: 0 10% 2% 10%;
    object-fit: contain;
  }
}

.imgText{
  @include flex-display(row,center,center);
  width: 100%;
  height: 100%;
  padding: 0 10%;
  img{
    width: 60%;
  }
  div{
    height: 100%;
    padding: 0 5%;
    @include flex-display(column,flex-end,flex-start);
    span:nth-child(1){
      @include normal-text($fontSize:1.875rem);
      color: $primaryColor;
    }
    span:nth-child(2){
      padding-top: 10%;
      @include normal-text($lineHeight:1.5rem);
    }
  }
}

@include mobile{
  .swiper{
    .img{
      padding-bottom: 3%;
    }
    .imgWithPadding{
      padding-bottom: 5%;
      max-height: none;
    }
  }

}