@import "src/shared/variables";
.container{
  @include flex-display(column);
  width: 100%;
  padding: 0;
  position: relative;
  box-sizing: border-box;

  &:hover {
    .thumbnail{
      filter: none;
      opacity: 1;
    }
    .onPic{
      .bgBlock{
        visibility: visible;
      }
      .projectName{
        color: white;
      }
      .briefIntro{
        visibility: visible;
      }
    }
  }
}
.thumbnail{
  position: relative;
  width: 50%;
  filter: grayscale(100%);
  opacity: .9;
  mask-image: linear-gradient(180deg, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%);
}

.onPic{
  position: absolute;
  @include flex-display(column);
  bottom: 6.5rem;
  left:25%;
  width: 75%;

  .keywords{
    @include normal-text();
    font-weight: bold;
    color: $primaryColor;
    width: 65%;
    position: relative;
    left:35%;
    @include lineClamp();
  }
  .bgBlock{
    width: 100%;
    background: $primaryColor;
    opacity: 0.5;
    margin-top: 1rem;
    padding: 2rem 0;
    visibility: hidden;
  }
  .projectName{
    @include normal-text($fontSize:3.75rem);
    color: #fde1bd;
    font-weight: bold;
    font-style: italic;
    line-height: 4rem;
    padding-left: 1rem;
    padding-right: 3px;
    position: relative;
    left:0;
    top:0;
    z-index: 3;
    opacity: 1;
    visibility: hidden;
    @include lineClamp();

  }
  .briefIntro{
    @include normal-text(true,normal,true,1rem);
    @include lineClamp(3);
    padding: 0 1rem;
    visibility: hidden;
  }
}

.basic{
  @include flex-display(column, center, flex-start);
  @include normal-text(false,normal,true,0.875rem);
  width: 25%;
  padding-bottom: 10rem;
}

@include mobile{
  .thumbnail{
    filter: none;
    opacity: 1;
  }
  .onPic{
    .bgBlock{visibility: visible}
    .projectName{
      font-size: 2.2rem;
      line-height: 2.5rem;
      color: white;
      //overflow: visible;
    }
    .briefIntro{
      visibility: visible;
    }
  }

  .briefIntro{
    visibility: visible;
    font-size: .875rem;
  }
  .basic{
    font-size: .8rem;
  }
}