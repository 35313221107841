@import "src/shared/variables";
.container{
  width: 100%;
  position: relative;
  .videoFrame{
    position: relative;
    height: 100vh;
    overflow-y: hidden;
  }
}
.description{
  position: absolute;
  top: 90vh;
  left: 3vw;
  z-index: 2;
  font-family: Calibri, sans-serif;

  .author{
    font-size: .875rem;
    color: $primaryColor;
  }
  .name{
    color: $secondaryColor;
    @include flex-display(row,flex-start,flex-end);
    margin: 10px 0;
    div:nth-child(1){
      font-weight: bold;
      font-size: 1.5rem;
      color: $primaryColor;
      margin-right: 12px;
    }
    div:nth-child(2){
      font-size: .875rem;
      margin-bottom: 4px;
      user-select: none;
      cursor: pointer;
    }
  }
  .intro{
    @include normal-text(true,1.6);
    width: 31%;
  }
}
.waterPrint{
  position: absolute;
  z-index: 3;
  opacity: .4;
  top: 50vh;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 20%;
  user-select: none;
  pointer-events: none;
  -webkit-user-drag: none;
}

@include mobile{
.waterPrint{
  opacity: .2;
  width: 30%;
  top:40%
}
}