@import "src/shared/variables.scss";
*{
    box-sizing: border-box;
}
.container{
    background: transparent;
    height: var(--containerHeight);
    overflow: hidden;
    @include flex-display(column);
    position: relative;
}

.main{
    position: relative;
    top:0;
    left: 0;
    height: 80%;
    $translateSize:calc(var(--activeSlideIndex) * var(--containerWidth) * -1);
    transform: translateX($translateSize);
    @include flex-display(row);
    transition: transform 0.5s ease-in-out;
    .slide{
        width: var(--containerWidth);
        @include flex-display(row,flex-start,center);
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
.controller{
    position: relative;
    bottom: 0;
    left:0;
    height: 20%;
    $padding: calc(var(--gap) / 2);
    $translateSize: calc(calc(var(--containerWidth) / var(--imagesPerSlide)) * ((var(--imagesPerSlide) - 1) / 2 - var(--activeSlideIndex)) );
    transform: translateX($translateSize);

    @include flex-display(row);
    transition: transform 0.5s ease-in-out;
    .slide{
        width: calc(var(--containerWidth) / var(--imagesPerSlide) );
        padding: 0 $padding;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            user-select: none;
        }
    }
}
.slide{
    height: 100%;
}

.slide.activeSlide{
    border: 3px solid $secondaryColor;
}
.prev{
    position: absolute;
    top:40%;
    left:0;
    background: transparent;
    border: none;
    color:$primaryColor;
    transform: scale(2.5);
    cursor: pointer;
}
.next{
    position: absolute;
    top:40%;
    right:0;
    background: transparent;
    border: none;
    color:$primaryColor;
    transform: scale(2.5);
    cursor: pointer;
}
.pagination{
    position: absolute;
    bottom:21%;
    right:50%;
    color: white;
    transform: translateX(50%);
    @include flex-display(row);
    button{
        background: transparent;
        border: none;
        color:$primaryColor;
        cursor: pointer;
    }
}

@include mobile{
    .controller{
        height: 10%;
    }
    .next{
        transform:scale(1.5);
    }
    .prev{
        transform:scale(1.5);
    }
}