@import "../shared/variables";
.footer{
  @include flex-display(column, space-between, center);
  width: 100%;
  padding: 10px 20px 5% 20px;
}
.divider{
  width: 96%;
  border-top: 1px solid $primaryColor;
  height: 0;
  margin-bottom: 4vw;
}
.footerMain{
  @include flex-display(row,center,center);
  width: 20%;
  .logo{
    width: 3.5rem;
    user-select: none;
    -webkit-user-drag: none;
    img{
      width: 100%;
      transform: translateX(-6%);
    }
    @include normal-text();
    color: $secondaryColor;
    text-align: center;
    letter-spacing: .2rem;
  }
  .info{
    @include flex-display(column,space-between,center);
    width: 13rem;
    height: 4.6rem;
    padding: 0 8%;
    .social{
      @include flex-display(row,space-between,center);
      width: 100%;
    }
    .email{
      @include normal-text(false);
      font-size: .9rem;
    }
  }
}
.copywrite{
  @include normal-text(false);
  font-size: .8rem;
  margin-top: 2%;
  transform: translateX(-5%);
}


@include mobile{
  .footerMain{
    width: 35%;
    .logo{
      //width: 2.2rem;
      height: 100%;
      font-size: .8rem;
      letter-spacing: .1rem;
    }
    .info{
      padding-left: 2rem;
      .email{
        padding-bottom: 0.5rem;
      }
    }
  }
  .copywrite{
    font-size: .8rem;
    margin-top: 1.2rem;
  }
}