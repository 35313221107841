@import "src/shared/variables";

.container{
  @include flex-display(column,flex-start,center);
  width: 100%;
  overflow-x: hidden;

}
.topPic{
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
  object-fit: cover;
}
.picInfo{
  position: absolute;
  z-index: 3;
  top: 93vh;
  left: 2%;
  @include normal-text(true,normal,true,0.875rem);
  font-style: italic;
  color: $secondaryColor;
}
.quote{
  @include normal-text(true,normal,true,1.5rem);
  position: absolute;
  z-index: 3;
  top: 45vh;
}
.imageBoard{
  width: 70%;
  margin-top: 10%;
}
/** Masonry */
.masonryContainer{
  width: 100%;
  height: 100%;
  @include flex-display();
  box-sizing: border-box;
  img{
    width: 100%;
    padding:.5rem;
    object-fit: cover;
  }
}

.masonryCol{
  @include flex-display(column);
  width: 50%;
  height: 100%;
}
.shortWrapper{
  width: 100%;
  height: 55%;
  position: relative;
  padding-top: 90%;
  box-sizing: content-box;
  img{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;height: 100%;
  }
}
.longWrapper{
  width: 100%;
  height: 45%;
  position: relative;
  padding-top: 130%;
  img{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;height: 100%;
  }
}
.load{
  animation: fade-in 3s;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

@include mobile{
  .topPic{
    height: 40vh;
    object-fit: cover;
  }
  .picInfo{
    display: none;
  }
  .quote{
    top:18vh;
    font-size: 1rem;
  }
  .title{
    top: 15vh;
  }
  .imageBoard{
    width: 85%;
  }
  .masonryContainer{
    img{
      padding: .3rem;
    }
  }
}